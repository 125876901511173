//Internal
import Button from '../../helpers/Button';
import Section from '../../layout/Section';
import TransportationLogo from '../../assets/TransportationLogo.webp';
//CSS
import classes from './AboutUs.module.css';

export default function AboutUs() {
  return (
    <Section id="aboutUs" title="About Us">
      <div className={classes.aboutUs}>
        <div className={classes.imageHolder}>
          <img src={TransportationLogo} alt="BBI Logistics logo" />
        </div>

        <div className={classes.textHolder}>
          <p>
            BBI Transportation, Inc is an asset-based trucking company located
            in Columbus, OH. Over the next 3-5 years, we are looking to grow our
            national footprint to 5-10 terminals across the US. This will help
            strengthen our overall position in the logistics and transportation
            industry by being able to place assets based on our customer’s
            needs.
          </p>

          <Button
            invert={true}
            onClick={() =>
              window.open('https://form.jotform.com/232743541661152', '_blank')
            }
          >
            Get a quote
          </Button>
        </div>
      </div>
    </Section>
  );
}
