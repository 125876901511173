import Section from '../../layout/Section';
import classes from './OurMission.module.css';
import Arrow from '../../assets/icons/Arrow.png'

export default function OurMission() {
  return (
    <Section title="Our Mission">
      <p className={classes.oMContent}>
        To be the premier asset-based trucking company that drives innovation,
        efficiency, and reliability in the transportation industry. We are
        dedicated to delivering exceptional freight solutions while fostering
        strong partnerships with our clients, employees, and communities.
      </p>
      <img src={Arrow} className={classes.arrow} alt="Arrow pointing to rest of website"/>
    </Section>
  );
}
