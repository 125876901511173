import classes from './App.module.css';
import Footer from './layout/Footer';
import HeaderBanner from './layout/HeaderBanner';
import AboutUs from './pages/home/AboutUs';
import BBILogistics from './pages/home/BBILogistics';
import CareersAndServices from './pages/home/CareersAndServices';
import Home from './pages/home/Home';
import OurMission from './pages/home/OurMission';

//GSAP
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { ScrollSmoother } from 'gsap/all';
import { useLayoutEffect, useState } from 'react';

export default function App() {
  const [gsapStatus, setGsapStatus] = useState();

  let mm = gsap.matchMedia();

  // add a media query. When it matches, the associated function will run
  gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
  useLayoutEffect(() => {
    mm.add('(min-width: 800px)', () => {
      // create our context. This function is invoked immediately and all GSAP animations and ScrollTriggers created during the execution of this function get recorded so we can revert() them later (cleanup)
      let ctx = gsap.context(() => {
        ScrollTrigger.normalizeScroll(true);
        // create the smooth scroller FIRST!
        ScrollSmoother.create({
          smooth: 0.75,
          smoothTouch: true,
          effects: true,
          normalizeScroll: true,
          ease: 1,
          onUpdate: self => setGsapStatus(Math.round(self.progress * 100)),
        });
      }); // <- IMPORTANT! Scopes selector text
      return () => ctx.revert(); // cleanup
    });
  }, []); // <- empty dependency Array so it doesn't re-run on every render

  return (
    <div id="smooth-wrapper">
      <HeaderBanner />
      <div className={classes.app} id="smooth-content">
        <Home />
        <OurMission />
        <CareersAndServices />
        <AboutUs />
        <BBILogistics />
        <Footer />
      </div>
    </div>
  );
}
