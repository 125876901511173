//Internal
import Card from '../../helpers/Card';
import Driver from '../../assets/Driver.webp';
import Dispatcher from '../../assets/Dispatcher.webp';
import Title from '../../helpers/Title';
import Button from '../../helpers/Button';
//Assets
import Truck from '../../assets/Truck.webp';
import HeadPhones from '../../assets/icons/Headphones.svg';
import TruckIcon from '../../assets/icons/Truck.svg';
import Snowflake from '../../assets/icons/Snowflake.svg';
import Medal from '../../assets/icons/Medal.svg';
import Tanker from '../../assets/icons/Tanker.svg';
import Trailer from '../../assets/icons/Trailer.svg';
import Flatbed from '../../assets/icons/Flatbed.svg';
//CSS
import classes from './CareersAndServices.module.css';

export default function Careers() {
  return (
    <div className={classes.careersAndServices}>
      <div className={classes.title} id="careers">
        <Title>Careers</Title>
      </div>

      <Card>
        <img src={Driver} alt="Picture of a truck driver" />
        <div className={classes.subtitle}>
          <Title alternate={true}>On the road</Title>
        </div>
        <p>
          Join our elite team of drivers today! Your dedication to safety,
          professionalism, and reliability will help us achieve our mission of
          delivering exceptional transportation solutions.
        </p>
        <Button
          onClick={() =>
            window.open(
              'https://recruiting.paylocity.com/recruiting/jobs/All/9c232b6b-d448-4861-b27b-bf7f955622f5/Hemphill-Son-Co',
              '_blank',
            )
          }
        >
          Become a driver
        </Button>
      </Card>

      <Card>
        <img src={Dispatcher} alt="Picture of a dispatcher" />
        <div className={classes.subtitle}>
          <Title alternate={true}>In the office</Title>
        </div>

        <p>
          Apply now! Your focus on compliance, customer service, and continuous
          improvement will contribute to our mission of providing exceptional
          transportation solutions.
        </p>
        <Button
          onClick={() =>
            window.open(
              'https://recruiting.paylocity.com/recruiting/jobs/All/9c232b6b-d448-4861-b27b-bf7f955622f5/Hemphill-Son-Co',
              '_blank',
            )
          }
        >
          Office Jobs
        </Button>
      </Card>

      <div className={classes.title} id="services">
        <Title>Services</Title>
      </div>

      <Card>
        <div className={classes.cardGrid}>
          <Title large={true} alternate={true}>
            What we offer
          </Title>
          <img src={Truck} alt="Truck driver" />
          <p>
            We offer a range of essential services and logistics solutions for
            all businesses seeking seamless supply chain management. We
            understand the importance of route planning, optimization, real-time
            tracking, communication, and transparency. These are all tools that
            help us minimize costs for our customers while exceeding their
            expectations. <br />
            <br /> Our fleet of well-maintained vehicles and diverse equipment
            can provide capacity to you in a variety of ways. The combination of
            our reliable assets and drivers will provide you with the service
            you are looking for.
          </p>
        </div>
        <div className={classes.iconRow}>
          <div>
            <img src={HeadPhones} alt="Brokerage Icon" />
            <span>Brokerage</span>
          </div>
          <div>
            <img src={TruckIcon} alt="Van Icon" />
            <span>Dry Van</span>
          </div>
          <div>
            <img src={Snowflake} alt="Snowflake Icon" />
            <span>Refrigerated</span>
          </div>
          <div>
            <img src={Medal} alt="Medal Icon" />
            <span>Dedicated</span>
          </div>
          <div>
            <img src={Tanker} alt="Tanker Icon" />
            <span>Tanker</span>
          </div>
          <div>
            <img src={Trailer} alt="Trailer Icon" />
            <span>Trailer Leasing</span>
          </div>
          <div>
            <img src={Flatbed} alt="Flatbed Icon" />
            <span>Flatbed</span>
          </div>
        </div>
      </Card>
    </div>
  );
}
