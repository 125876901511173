//External
import { useEffect, useState } from 'react';
import { Link } from 'react-scroll';
//React-icons
import { GiHamburgerMenu } from 'react-icons/gi';
import { GrClose } from 'react-icons/gr';
//Internal
import BBILogo from '../assets/BBILogo.webp';
//CSS
import classes from './HeaderBanner.module.css';

export default function HeaderBanner() {
  const [scrolled, setScrolled] = useState(false);
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);

  useEffect(() => {
    // Add an event listener to the window to track scrolling
    window.addEventListener('scroll', handleScroll);
    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Check if the user has scrolled down at least 100 pixels
    if (window.scrollY >= 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  return (
    <div
      className={`${classes.headerBanner} ${scrolled && classes.scrolled} ${
        isBurgerOpen && classes.menuExpand
      }`}
    >
      <div className={classes.linkContainer}>
        <Link
          to="careers"
          spy={true}
          smooth={true}
          offset={-100}
          duration={400}
        >
          Careers
        </Link>

        <Link
          to="services"
          spy={true}
          smooth={true}
          offset={-100}
          duration={400}
        >
          Services
        </Link>

        <Link
          to="aboutUs"
          spy={true}
          smooth={true}
          offset={-100}
          duration={400}
        >
          About Us
        </Link>
      </div>

      {!isBurgerOpen ? (
        <GiHamburgerMenu
          onClick={() => setIsBurgerOpen(!isBurgerOpen)}
          className={classes.menuBurger}
        />
      ) : (
        <>
          <GrClose
            onClick={() => setIsBurgerOpen(!isBurgerOpen)}
            className={classes.menuBurger}
          />

          <div className={classes.burgerContainer}>
            <Link
              to="careers"
              spy={true}
              smooth={true}
              offset={-100}
              duration={400}
              onClick={() => setIsBurgerOpen(false)}
            >
              Careers
            </Link>

            <Link
              to="services"
              spy={true}
              smooth={true}
              offset={-100}
              duration={400}
              onClick={() => setIsBurgerOpen(false)}
            >
              Services
            </Link>

            <Link
              to="aboutUs"
              spy={true}
              smooth={true}
              offset={-100}
              duration={400}
              onClick={() => setIsBurgerOpen(false)}
            >
              About Us
            </Link>
          </div>
        </>
      )}

      <Link
        to="aboveTheFold"
        spy={true}
        smooth={true}
        offset={-100}
        duration={400}
        onClick={() => setIsBurgerOpen(false)}
      >
        <img src={BBILogo} alt="BBI Logo" />
      </Link>

      <div />

      <div className={classes.backgroundTransition} />
    </div>
  );
}
