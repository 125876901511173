import classes from './Home.module.css';
import Button from '../../helpers/Button';
import { useEffect, useState } from 'react';

export default function Home() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    // Add an event listener to the window to track scrolling
    window.addEventListener('scroll', handleScroll);
    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Check if the user has scrolled down at least 100 pixels
    if (window.scrollY >= 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  return (
    <div className={classes.container} id="aboveTheFold">
      <video className={classes.home} loop autoPlay muted playsInline id="vid">
        <source
          src="https://bbiwebimages.blob.core.windows.net/videos/AboveTheFold.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <div className={classes.cTA}>
        <Button
          onClick={() =>
            window.open(
              'https://recruiting.paylocity.com/recruiting/jobs/All/9c232b6b-d448-4861-b27b-bf7f955622f5/Hemphill-Son-Co',
              '_blank',
            )
          }
        >
          Become a driver
        </Button>
      </div>
      <div className={`${classes.arrowEnd} ${scrolled && classes.scrolled}`} />
    </div>
  );
}
