import Title from '../helpers/Title';
import classes from './Section.module.css';

export default function Section({ title, children, ...rest }) {
  return (
    <div className={classes.section} {...rest}>
      <Title>{title}</Title>
      <div className={classes.content}>{children}</div>
    </div>
  );
}
