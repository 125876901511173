//External
import { PiCaretUpBold } from 'react-icons/pi';
import { Link } from 'react-scroll';
//Internal
import FooterImgFull from '../assets/Footer_Full.webp';
import FooterImg from '../assets/Footer.webp';
//CSS
import classes from './Footer.module.css';

export default function Footer() {
  return (
    <>
      <div className={classes.footer}>
        <img
          src={FooterImg}
          className={classes.footerImgDesktop}
          alt="background image with BBI logo"
        />
        <img
          src={FooterImgFull}
          className={classes.footerImgMobile}
          alt="background image with BBI logo"
        />

        <div className={classes.address}>
          <div>BBI Transportation</div>

          <div>
            <i>1 (888) 982 9782</i>
          </div>

          <div>
            <i>info@bbilogistics.com</i>
          </div>

          <div className={classes.accent} />
        </div>
      </div>

      <div className={classes.navIcon}>
        <Link
          to="aboveTheFold"
          spy={true}
          smooth={true}
          offset={-100}
          duration={400}
        >
          <PiCaretUpBold />
        </Link>
      </div>
    </>
  );
}
