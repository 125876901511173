import classes from './Title.module.css';

export default function Title({ alternate, large, children }) {
  return (
    <div
      className={`${classes.title} ${alternate && classes.alternate} ${
        large && classes.large
      }`}
    >
      <span>{children}</span>
      <div className={classes.accent} />
    </div>
  );
}
