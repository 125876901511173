//Internal
import BBILogisticsBuilding from '../../assets/BBILogisticsBuilding.webp';
import Button from '../../helpers/Button';
//CSS
import classes from './BBILogistics.module.css';

export default function BBILogistics() {
  return (
    <div className={classes.container}>
      <img
        className={classes.home}
        src={BBILogisticsBuilding}
        alt="Picture of BBI Logistics Office"
      />

      <div className={classes.cTA}>
        <div className={classes.title}>BBI Logistics</div>

        <p>
          Providing services and supply-chain solutions by leveraging internal
          data and technology. Our goal is to help customers find the most
          cost-effective and reliable carrier while also maximizing load
          potential for our carriers.
        </p>

        <div className={classes.btnHolder}>
          <Button
            onClick={() =>
              window.open('https://www.BBILogistics.com', '_blank')
            }
          >
            Visit Website
          </Button>
        </div>
      </div>
    </div>
  );
}
