import classes from './Button.module.css';

export default function Button({ invert, children, ...rest }) {
  return (
    <button
      className={`${classes.button} ${invert && classes.invert}`}
      {...rest}
    >
      <span>{children}</span>
    </button>
  );
}
